.purple-shade {
	background-color: $purple-shade !important;
	color: #fff !important;
}
.orange-shade {
	background-color: $orange-shade !important;
	color: #fff !important;
}
.yellow-shade {
	background-color: $yellow-shade !important;
	color: #625b5b !important;
}
.green-shade {
	background-color: $green-shade !important;
	color: #fff !important;
}
.blue-shade {
	background-color: $blue-shade !important;
	color: #fff !important;
}
.sky-shade {
	background-color: $sky-shade !important;
	color: #fff !important;
}
.red-shade {
	background-color: $red-shade !important;
	color: #fff !important;
}
.right-content {
	margin-left: 220px;
	width: 100%;
}
.main-content {
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 86px;
	width: 100%;
	position: relative;
	min-height: calc(100vh - 60px);

	&.citizen {
		padding-top: 120px;
	}
}
.top-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.main-title {
	color: $primary;
	font-size: 2rem;
	font-size: 2rem;
	display: inline-block;
	border-bottom: 3px solid $primary;
}
.color-primary {
	color: $primary;
}
.card {
	border: none;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
	background-color: #fff;
	border-radius: 3px;
	border: none;
	position: relative;
	margin-bottom: 30px;
}
.card-header {
	background-color: #fff;
}
.card-header-custom {
	border-bottom-color: #f9f9f9;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	display: flex;
	align-items: center;
	h3 {
		font-size: 24px;
		line-height: 28px;
		color: #6777ef;
		padding-right: 10px;
		margin-bottom: 0;
		font-weight: 600;
	}
}

.card-footer {
	border-top: none;
}
.card-header-tabs {
	margin-left: 0;
	margin-right: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	background-color: $primary;
	color: #fff;
	border: none;
}
.nav-tabs {
	.nav-link {
		border: 1px solid $primary;
		color: $primary;
		// background-color: $primary-100;
		border-radius: 4px;
		margin-right: 10px;
	}
}
.template-card {
	margin-bottom: 30px;
}
.p-relative {
	position: relative;
}
.wizard-steps {
	display: flex;
	margin: 0 -25px;
	margin-bottom: 70px;
	counter-reset: wizard-counter;
	.wizard-step {
		background-color: #fff;
		border-radius: 3px;
		border: none;
		position: relative;
		margin-bottom: 30px;
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
		padding: 20px 30px;
		text-align: center;
		flex-grow: 1;
		flex-basis: 0;
		margin: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		&::before {
			counter-increment: wizard-counter;
			content: counter(wizard-counter);
			position: absolute;
			bottom: -40px;
			left: 50%;
			transform: translateX(-50%);
			width: 20px;
			height: 20px;
			line-height: 21px;
			font-size: 10px;
			font-weight: 700;
			border-radius: 50%;
			background-color: #e3eaef;
		}
	}
	.wizard-step-active {
		box-shadow: 0 2px 6px #acb5f6;
		background-color: $primary;
		color: #fff;
		&::before {
			background-color: #6777ef;
			color: #fff;
		}
	}
}

.nav-buttons {
	position: absolute;
	top: 90px;
	// top: 70px;
}
.btn-left {
	left: 0;
}
.btn-right {
	right: 0;
}
.page {
	background: white;
	display: block;
	margin: 0 auto;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

.a4 {
	background: white;
	display: block;
	margin: 0 auto;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
	width: 21cm;
	height: 29.7cm;
}

.zoom .page {
	position: relative;
}
.zoomBtn {
	position: absolute;
	top: 10px;
	right: 20px;
}
.gallery-item {
	width: 120px;
	height: 120px;
	object-fit: cover;
	position: relative;
}
.gallery-img {
	width: 120px;
	height: 120px;
	position: relative;
}

.brand-logo {
	width: 80px;
	object-fit: scale-down;
}
.pointer {
	cursor: pointer !important;
}

.deleteIcon {
	position: absolute;
	top: -10px;
	right: -10px;
	background-color: $danger;
	width: 20px;
	height: 20px;
	// border-radius: 50%;
	// display: grid;
	// place-items: center;
	color: #fff;
	z-index: 99;
	&:hover {
		transform: scale(1.2);
	}
}

.custom-search {
	padding: 0.5em 1em !important;
	height: 38px;
	border-radius: 30px 0 0 30px !important;
	&:focus {
		outline: none;
		box-shadow: none;
	}
}

.input-search-icon {
	border-radius: 0 30px 30px 0 !important;
}

// Dashboard
.dashboard-stats-box {
	background-color: $white;
	box-shadow: $box-shadow;
	padding: 30px 20px 20px 20px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
	position: relative;
	color: $primary;
	&:hover {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
		background-color: $primary;
		color: $white;
	}
	.dashboard-stats-box-icon svg {
		width: 30px;
		height: 30px;
	}
	span {
		position: absolute;
		top: -25px;
		left: 45%;
		background-color: $white;
		color: $primary !important;
		border-radius: 50%;
		box-shadow: $box-shadow;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		font-weight: 600;
	}
}

.custom-card {
	padding: 5px 10px;
}
.support-img {
	height: 400px;
}

.accordion {
	margin-top: 20px;
	.accordion-item {
		border-bottom: 1px solid $primary-100;
		button[aria-expanded='true'] {
			border-bottom: 1px solid $primary;
		}
	}
	button {
		position: relative;
		display: block;
		text-align: left;
		width: 100%;
		padding-bottom: 1rem;
		color: $secondary;
		font-size: 1.15rem;
		font-weight: 400;
		border: none;
		background: none;
		outline: none;
		&:hover,
		&:focus {
			cursor: pointer;
			color: $primary;
			&::after {
				cursor: pointer;
				color: $primary;
				border: 1px solid $primary;
			}
		}
		// .accordion-title {
		//   padding: 1em 1.5em 1em 0;
		// }
		.icon {
			display: inline-block;
			position: absolute;
			top: 10px;
			right: 0;
			width: 22px;
			height: 22px;
			border: 1px solid;
			border-radius: 22px;
			&::before {
				display: block;
				position: absolute;
				content: '';
				top: 9px;
				left: 5px;
				width: 10px;
				height: 2px;
				background: currentColor;
			}
			&::after {
				display: block;
				position: absolute;
				content: '';
				top: 5px;
				left: 9px;
				width: 2px;
				height: 10px;
				background: currentColor;
			}
		}
	}
	button[aria-expanded='true'] {
		color: $primary;
		.icon {
			&::after {
				width: 0;
			}
		}
		+ .accordion-content {
			opacity: 1;
			max-height: 9em;
			transition: all 200ms linear;
			will-change: opacity, max-height;
		}
	}
	// .card {
	//   margin-top: 10px;
	// }
}

.border-dotted {
	border-style: dotted !important;
}

.border-2 {
	border-width: 2px !important;
}

.form-error {
	border: 1px solid red !important;
}

#consent-hr {
	border-top: 2px solid #ccc !important;
}

.form-group {
	.form-label,
	label {
		width: 100%;
	}
	input,
	select {
		width: 100% !important;
		margin: 0 !important;
	}
}
