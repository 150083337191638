.login-logo {
	width: 150px !important;
}
.login-logo img {
	width: 100%;
}

.title {
	color: #da0510;
}
.title h5 {
	margin-bottom: 5px;
	font-size: 22px;
}
.title h3 {
	margin-bottom: 5px;
	font-size: 32px;
	font-weight: 600;
}
.login-brand {
	margin: 20px 0;
	margin-bottom: 20px;
	color: #666;
	text-align: center;

	img {
		margin-bottom: 10px;
	}
	h3 {
		color: $red-shade;
	}
	p {
		color: $primary;
	}
}
.card.card-primary {
	border-top: 2px solid #6777ef;
}
.card .card-header h3 {
	font-size: 16px;
	line-height: 28px;
	color: #6777ef;
	padding-right: 10px;
	margin-bottom: 0;
}
.text-small {
	font-size: 12px;
	line-height: 20px;
}
.simple-footer {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
	a {
		text-decoration: none;
		color: grey;
		font-weight: bold;
	}
}
.form-group .control-label,
.form-group > label {
	font-weight: 600;
	color: #34395e;
	font-size: 14px;
	letter-spacing: 0.5px;
}
.powered {
	height: 60px;
}

// login with consent page

.login-sso {
	width: 100vw;
	height: 100vh;
	display: grid;
	place-content: center;

	.max-w-300 {
		max-width: 600px;
	}

	.card {
		position: relative;
		z-index: 10;
		border-top: none;
		border-radius: 8px;
		box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
		overflow: hidden;
	}
	.card-header {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		column-gap: 1rem;
		border: none;
		padding: 1.5rem;
	}
	.divider {
		position: relative;
		isolation: isolate;
		display: flex;
		justify-content: center;
		color: $primary;
		font-size: 1.5rem;
		font-weight: 600;
		p {
			background: white;
			padding-inline: 1rem;
		}
		&::before {
			content: '';
			position: absolute;
			inset-inline: 0;
			height: 3px;
			margin-inline: 1rem;
			background-color: $primary;
			top: 50%;
			transform: translateY(-50%);
			z-index: -1;
		}
	}
	.control-label,
	.form-group .control-label,
	.form-group > label {
		font-weight: 600;
		color: #34395e;
		font-size: 14px;
		letter-spacing: 0.5px;
		width: 100%;
		margin-bottom: 0.25rem;
	}
	.nagarpalika-info {
		&-name {
			font-size: 1.2rem;
			color: $primary;
			line-height: 1;
			font-weight: 500;
		}
		&-slogan {
			font-weight: 500;
			color: $danger;
			line-height: 1.5;
		}
	}
	&-logo {
		width: 80px;
		margin-block: 0.5rem;
	}
	.btn {
		border: 1px solid #d0d0d0;
		border-radius: 8px;
		padding-block: 12px;
		width: 100%;
		font-size: 16px;
		&-neutral {
			background-color: #404040;
			color: white;
			padding-block: 0.5rem;
			margin-bottom: 0.5rem;
			border: none;
			font-size: 14px;
			font-weight: 600;
		}

		&-white {
			background-color: white;
			color: #404040;
		}
		&-login {
			background-color: #6677ef;
			color: white;
			padding-block: 0.5rem;
			margin-bottom: 0.5rem;
			border: none;
			font-size: 14px;
			font-weight: 600;
		}
		&:hover {
			border: none !important;
		}
	}
}
