.spin {
  animation: spin 2s ease infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.height-75vh {
  height: 75vh;
}
.A4 {
  height: fit-content;
}

.is-invalid {
  border-color: #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem);
  &:focus-visible {
    outline-color: #dc3545 !important;
  }
}
.navbar__link--active {
  background: #efefef !important;
  font-weight: bold;
}

.glassmorphism {
  background: rgba( 255, 255, 255, 0.5 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 6.5px );
  -webkit-backdrop-filter: blur( 6.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.aspect-square{
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}